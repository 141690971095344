<template>
  <div class="content">
    <Header>
      <div class="wrap">
        <div class="headimg">
          <img src="../img/headimg.png" alt="">
        </div>
        <div class="title">
          <div class="name">Lavie Innovations Technology</div>
          <div class="info">Make Your Life <br /> More Interesting!</div>
        </div>
      </div>
      <img class="ricon" src="../img/ricon.png" alt="">
    </Header>
    <div class="bg1" id="about">
      <div class="wrap wow animate__fadeInUp " data-wow-duration="0.5s" data-wow-delay="0s">
        <div class="aboutus">
          <div class="infotext">
            <div class="titlename" style="text-align: left;">Who Are We?</div>
            <div class="intro">Is your life boring? Want to make friends worldwide? Need something funny and meaningful?
              We are what you are looking for! Highly acclaimed voice-centric entertainment platform with games.
              You can not only play games here, but also find your friends.</div>
            <div class="com">Lavie Innovations Technology</div>
          </div>
          <div class="pic"><img src="../img/aboutus.png" alt=""></div>

        </div>
      </div>
    </div>
    <div class="bg2" id="products" style="display:none">
      <div class="wrap wow animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0s">
        <div class="products">
          <div class="titlename" style="margin-bottom: 44px;">Products</div>
          <div class="info">
            <div class="productsitem">
              <img class="productsimg" src="../img/pro_img1.png" alt="">
              <div class="product_content">
                <p class="productname">Waka Play</p>
                <div class="infotext">Waka Play is a classic board game to play with friends. Play great games! Get back to your childhood!</div>
              </div>
            </div>
            <div class="productsitem">
              <img class="productsimg" src="../img/pro_img2.png" alt="">
              <div class="product_content">
                <p class="productname">Tami  Match</p>
                <div class="infotext">Guess what you'll enjoy in Tami Match, a beautiful experience, a good friend or an amazing match? The answer is both! Come to Tami match and explore the story behind it. </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg4" id="contactus">
      <div class="wrap wow animate__fadeInUp " data-wow-duration="0.5s" data-wow-delay="0s">
        <div class="contu">
          <ContactUs></ContactUs>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import ContactUs from '@/components/ContactUs/ContactUs.vue'
export default {
  components: {
    ContactUs
  },
  watch: {
    "$route": {
      handler(val) {
        if (this.$route.query["about"]) {
          this.gotocontent("about")
        }
        if (this.$route.query["products"]) {
          this.gotocontent("products")
        }
        if (this.$route.query["contactus"]) {
          this.gotocontent("contactus")
        }
      }
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    new this.$wow.WOW({
      live: true
    }).init()
    if (this.$route.query["about"]) {
      this.gotocontent("about")
    }
    if (this.$route.query["products"]) {
      this.gotocontent("products")
    }
    if (this.$route.query["contactus"]) {
      this.gotocontent("contactus")
    }
  },
  methods: {
    slidepic() {
      const imgswiper = this.$refs.imgswiper
      console.log(imgswiper.scrollLeft);
      if (imgswiper.scrollLeft >= 1800) {
        imgswiper.scrollLeft = 1
      }
      if (imgswiper.scrollLeft <= 0) {
        imgswiper.scrollLeft = 1799
      }
      console.log(imgswiper.scrollLeft);
    },
    gotocontent(data) {
      document.getElementById(`${data}`).scrollIntoView()
      this.$router.push('/')
    }
  }

}
</script>

<style lang="scss" scoped src="./content.scss">

</style>