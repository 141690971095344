<template>
  <div class="footer">
    <div class="wrap">
      <div class="footernav">
        <!-- <div class="f_logo"><img src="../img/logo.png" alt=""></div> -->
        <div class="r">
          <Nav class="rnav" :hrefList="hrefList"></Nav>
        </div>
      </div>
      <div class="footerinfo">
       <p>© 2022 Lavie Innovations Technology All Rights Reserved.</p>
       <p class="addressInfo">142, Lavie Innovations Technology c/o Crosslinks, Royal Class Business Center, The Binary By Omniyat, Business Bay, Dubai, PO Box - 31436</p>
      </div>


    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.vue'
export default {
  data() {
    return {
      hrefList: ['About Us', 'Products', 'Contact Us']
    }
  },
  components: {
    Nav
  },
}
</script>

<style lang="scss" scoped src="./footer.scss">

</style>